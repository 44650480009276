<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				data:{},
				count_blog:0,
            }
		},
		computed:{
			myParams(){
				let query = Gen.clone(this.$route.query)
				if(this.$route.query.search) query.search = this.$route.query.search
				return query
			}
		},
		async mounted() {
			await this.ready();
			this.$set(this.$root, 'page', this);
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 300)
            this.getApi()
		},
        methods: {
        	getApi() {
				Gen.apirest("/list-blog",this.myParams,(err,resp)=>{
					if(err) console.log(err)
					this.data = resp.data
					this.count_blog = resp.count_blog
				})
			},
			onPaging(page = 1){
				if($(".page-item.active").text() == page) return 
				var query = Object.assign(Gen.clone(this.$route.query),{
					page:page
				})
				this.$router.push({
					name:this.$route.name,
					query:query
				})
			}
        },
        watch: {
			$route(){
				this.getApi()
			}
		}
	};
</script>
<template>
	<section id="content" style="background-color: #000; overflow: visible">
		<div class="content-wrap pt-0 pb-0">
			<div id="section-about" class="section page-section bg_0f181b nobottommargin notopmargin clearfix">
				<div class="clearfix">
					<div class="row justify-content-around dark">
						<div class="col-md-12">
							<div class="heading_section">
								<h2>{{web.mn_blog}}</h2>
							</div>
						</div>
					</div>
					<h3 v-if="count_blog<1 || data.data.length==0" style="color:#fff" class="text-center">
						{{ $route.query.search ? web.no_search_result+$route.query.search : web.lbl_no_data }}
					</h3>
					<div class="container">
						<div class="row">
							<div v-for="(v,k) in data.data" :key="k" class="col-lg-6 col-md-12 col-12" data-aos="fade-up" data-aos-duration="1000">
								<div class="blog_wrapper bg_brown">
									<div class="row">
										<div class="col-md-6">
											<div class="thumb_post">
												<router-link :to="{name:'DetailBlog', params:{slug:v.slug}}">
												<img :src="uploader(v.image)" :alt="v.title" :title="v.title">
											</router-link>
											</div>
										</div>
										<div class="col-md-6 pl-md-0">
											<div class="blog_content_post">
												<h3>
													<router-link :to="{name:'DetailBlog', params:{slug:v.slug}}">
														{{ v.title }}
													</router-link>
												</h3>
												<p v-html="(v.excerpt).limitChar(100)"></p>
												<ul>
													<li><a href="javascript:;">{{ (v.publish_date||"").dates("format") }}</a></li>
				                                    <li><a href="javascript:;">{{ v.author }}</a></li>
												</ul>
												<router-link :to="{name:'DetailBlog', params:{slug:v.slug}}" class="btn_act">{{ web.btn_view }}</router-link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<Pagination class="justify-content-center mt-5" :data="data" :limit="8" @pagination-change-page="onPaging"></Pagination>
					</div>
				</div>
			</div>
			<div class="clear"></div>
		</div>
	</section>
</template>